import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import i18n from "./i18n"

const app = createApp(App)


import createFilters from "./filters/filters";
createFilters(app);


// tailwind
import './styles/tailwind.scss'
// import './styles/scss/app.scss'

// ToastNotification
// @ts-ignore
import Toast, { PluginOptions, POSITION } from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

const options: PluginOptions = {
    position: POSITION.TOP_RIGHT,
    timeout: 1500,
};
app.use(Toast, options);

app.use(i18n).use(store).use(router).mount('#app')
