import axios from "@/http/http";
import {Module, ActionTree} from "vuex";

const actions: ActionTree<any, any> = {
    fetchCourseList({commit, dispatch}, data) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            try {
                commit("SET_LOADING", true);
                const response = await axios.get("/api/v1/courses/list/", {params: data});

                commit("setCourses", response.data);
                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },
    fetchCourseDetails({commit, dispatch}, data) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            try {
                commit("SET_LOADING", true);
                const response = await axios.get("/api/v1/courses/course/details/", {params: data});

                commit("setCourse", response.data);
                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },
    fetchCourseAssessmentInfo({commit, dispatch}, data) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });
            try {
                commit("SET_LOADING", true);
                const response = await axios.post("/api/v1/assessments/info/", formData);
                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },
    submitCourseAssessment({commit, dispatch}, data) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });
            try {
                commit("SET_LOADING", true);
                const response = await axios.post("/api/v1/assessments/submission/", formData);
                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },
    updateCourseWatchedStatus({commit, dispatch}, data) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const formData = new FormData();

            Object.keys(data).forEach((key: string) => {
                formData.append(key, data[key]);
            });
            try {
                // commit("SET_LOADING", true);
                const response = await axios.post("/api/v1/courses/course/video/watched/update/", formData);
                // commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                // commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },
};
const coursesStore: Module<any, any> = {
    state: () => ({
        course: null,
        courses: [],
    }),
    mutations: {
        setCourses(state, data) {
            state.courses = data.data;
        },
        setCourse(state, course) {
            state.course = course;
        },
    },
    actions,
    getters: {
        courses(state) {
            return state.courses;
        },
        course(state) {
            return state.course;
        }
    },
};

export default coursesStore;
