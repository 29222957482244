import { Module, ActionTree } from "vuex";

import axios from "@/http/http";

const actions: ActionTree<any, any> = {
    fetchAdminCourses({commit, dispatch}, params) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            try {
                commit("SET_LOADING", true);
                const { data } = await axios.get("/api/v1/courses/", {params});

                commit("setCourses", data.data);
                commit("SET_LOADING", false);
                resolve(data.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },
    fetchAdminColleges({commit, dispatch}, params) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            try {
                commit("SET_LOADING", true);
                const { data } = await axios.get("/api/v1/colleges/list/", { params });

                commit("setColleges", data.data);
                commit("SET_LOADING", false);
                resolve(data.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },
    fetchAdminBranches({commit, dispatch}, params) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            try {
                commit("SET_LOADING", true);
                const { data } = await axios.get("/api/v1/branches/list", {params});

                commit("setBranches", data.data);
                commit("SET_LOADING", false);
                resolve(data.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },
    fetchCourseAnalytics({commit, dispatch}, params) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            try {
                commit("SET_LOADING", true);
                const { data } = await axios.get("/api/v1/courses/analytics/", {params});

                commit("setAnalyticsData", data.data);
                commit("SET_LOADING", false);
                resolve(data.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },
    exportToExcel({commit, dispatch}, params) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            try {
                commit("SET_LOADING", true);
                await axios.get("/api/v1/courses/analytics/export/", {params, responseType: "blob"})
                .then((response) => {
                    // download the file
                    const fileName = `analytics-${params.course_id}-${params.college_id}.csv`;
                    const url = window.URL.createObjectURL(response.data);
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `${fileName}`);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                });

                commit("SET_LOADING", false);
                resolve(true);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    }
};

const analyticsStore: Module<any, any> = {
    state: () => ({
        adminColleges: [],
        adminBranches: [],
        adminCourses: [],
        analyticsData: null,
    }),
    mutations: {
        setColleges(state, colleges) {
            state.adminColleges = colleges;
        },
        setBranches(state, branches) {
            state.adminBranches = branches;
        },
        setCourses(state, courses) {
            state.adminCourses = courses;
        },
        setAnalyticsData(state, data) {
            state.analyticsData = data;
        }
    },
    actions,
    getters: {
        adminColleges(state) {
            return state.adminColleges;
        },
        adminBranches(state) {
            return state.adminBranches;
        },
        adminCourses(state) {
            return state.adminCourses;
        },
        analyticsData(state) {
            return state.analyticsData;
        }
    },
};

export default analyticsStore;
