import moment from "moment";
import {baseUrlNoEndSlash} from "@/http/global_urls";
import {default as toast} from "@/utils/toastUtils";

function createFilters(app: any) {
    app.config.globalProperties.$toast = toast;
    app.config.globalProperties.$filters = {
        addBaseUrl(value: any) {
            if(!value) {
                return ''
            }
            if (value.startsWith("http")) {
                return value;
            }
            return baseUrlNoEndSlash + value;
        },
        durationConvert(value: any) {
            const num = value / 60;
            const hours = (num / 60);
            const rhours = Math.floor(hours);
            const minutes = (hours - rhours) * 60;
            const rminutes = Math.round(minutes);
            if(rhours > 0) {
                return rhours + " h " + rminutes + " m";
            } else {
                return rminutes + " m";
            }
        },
        niceDateFormat(value: any) {
            if (!value) {
                return "NA";
            }
            try {
                return moment(value).format("h:mm a, Do MMMM YYYY");
            } catch (e) {
                return "NA";
            }
        },
        niceOnlyDateFormat(value: any) {
            if (!value) {
                return "NA";
            }
            try {
                return moment(value).format("Do MMMM YYYY");
            } catch (e) {
                return "NA";
            }
        },
        serverOnlyDateFormat(value: any) {
            if (!value) {
                return "NA";
            }
            try {
                return moment(value).format("YYYY-MM-DD");
            } catch (e) {
                return "NA";
            }
        },
        roundNumber(value: any) {
            if (!value) {
                return "-";
            }
            return parseFloat(value).toFixed(2)
        },
        getUnits(value: any, label: any) {
            if (isNaN(value)) {
                return "";
            }
            if(label == "Energy") {
                return "kcal";
            }
            return "gms";
        }
    };
}

export default createFilters;
