<template>
  <TransitionRoot appear :show="isLoading" as="div">
    <div class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div class="w-16 h-16 border-4 border-t-4 border-blue-500 rounded-full animate-spin"></div>
    </div>
  </TransitionRoot>
</template>

<script>
import { TransitionRoot } from "@headlessui/vue";
import { mapGetters } from "vuex";

export default {
  name: "LLoading",
  components: {
    TransitionRoot,
  },
  computed: {
    ...mapGetters(["isLoading"]),
  },
};
</script>

<style>
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.fixed {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
